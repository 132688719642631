<template>
  <v-form ref="form">
    <v-card>
      <v-card-title>
        <span class="headline">Project Form</span>
      </v-card-title>
      <v-card-text>
        <v-progress-linear v-if="formLoading" indeterminate />
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="formData.name"
                :label="$t('project_name')"
                :disabled="formLoading"
                required
              />
            </v-col>

            <v-col cols="12" sm="6">
              <ApolloQuery
                :query="require('@/graphql/client/clientsDropdown.gql')"
              >
                <template v-slot="{ result: { loading, error, data } }">
                  <v-autocomplete
                    v-if="data"
                    :items="data.allClients"
                    :disabled="formLoading"
                    :loading="loading"
                    item-text="name"
                    item-value="id"
                    v-model="client_id"
                    label="Client"
                    required
                  />
                  <v-autocomplete v-else label="Loading Clients" />
                </template>
              </ApolloQuery>
            </v-col>
            <v-col cols="12" sm="6">
              <ApolloQuery
                :query="require('@/graphql/user/userDropdown.gql')"
                :variables="{
                  user_type: 'admin'
                }"
              >
                <template v-slot="{ result: { loading, error, data } }">
                  <v-autocomplete
                    v-if="data"
                    :items="data.allUsers"
                    :disabled="formLoading"
                    :loading="loading"
                    item-text="name"
                    item-value="id"
                    v-model="owner_id"
                    label="WD Project Owner"
                    required
                  />
                  <v-autocomplete v-else label="Loading Owners" />
                </template>
              </ApolloQuery>
              <ApolloQuery
                :query="require('@/graphql/user/userDropdown.gql')"
                :variables="{
                  client_id: formData.client.id
                }"
              >
                <template v-slot="{ result: { loading, error, data } }">
                  <v-autocomplete
                    v-if="data"
                    :items="data.allUsers"
                    :disabled="formLoading"
                    :loading="loading"
                    item-text="name"
                    item-value="id"
                    v-model="manager_id"
                    label="Client Project Manager"
                    required
                  />
                  <v-autocomplete v-else label="Loading Managers" />
                </template>
              </ApolloQuery>
            </v-col>

            <v-col cols="12" sm="6">
              <v-textarea
                name="description"
                label="Project Description"
                v-model="formData.description"
                hint="Enter description here..."
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red darken-1"
          text
          @click="$emit('project-form-closed')"
          :disabled="formLoading"
          >{{ $t("close") }}</v-btn
        >
        <v-btn
          v-if="formData.id"
          color="primary darken-1"
          text
          :loading="formLoading"
          :disabled="formLoading"
          @click="updateProject()"
          >{{ $t("save") }}</v-btn
        >
        <v-btn
          v-else
          color="primary darken-1"
          text
          :disabled="formLoading"
          :loading="formLoading"
          @click="createProject()"
          >{{ $t("create") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
export default {
  name: "ProjectForm",
  props: {
    project: {
      type: Object,
      default: () => ({
        id: "",
        name: "",
        description: "",
        users: [],
        owner: {},
        manager: {},
        client: {}
      })
    }
  },
  data: () => ({
    isValid: true,
    mutation: "",
    formLoading: false,
    defaultForm: {
      id: "",
      name: "",
      description: "",
      owner: {},
      manager: {},
      client: {},
      users: []
    },
    formData: {}
  }),
  created() {
    this.formData = { ...this.defaultForm, ...this.project };
  },
  computed: {
    owner_id: {
      // this feels wrong, but it works...
      // getter
      get: function() {
        if (typeof this.project.owner !== "undefined") {
          if (typeof this.project.owner.id !== "undefined") {
            return this.project.owner.id;
          } else {
            return "";
          }
        } else {
          return "";
        }
      },
      // setter
      set: function(newValue) {
        this.formData.owner = { id: newValue };
      }
    },
    client_id: {
      // this feels wrong, but it works...
      // getter
      get: function() {
        if (typeof this.project.client !== "undefined") {
          if (typeof this.project.client.id !== "undefined") {
            return this.project.client.id;
          } else {
            return "";
          }
        } else {
          return "";
        }
      },
      // setter
      set: function(newValue) {
        this.formData.client = { id: newValue };
      }
    },
    manager_id: {
      // this feels wrong, but it works...
      // getter
      get: function() {
        if (typeof this.project.manager !== "undefined") {
          if (typeof this.project.manager.id !== "undefined") {
            return this.project.manager.id;
          } else {
            return "";
          }
        } else {
          return "";
        }
      },
      // setter
      set: function(newValue) {
        this.formData.manager = { id: newValue };
      }
    }
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.isValid = true;
        this.saveForm();
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    updateProject() {
      this.mutation = require("@/graphql/project/updateProject.gql");
      this.saveForm();
    },
    createProject() {
      this.mutation = require("@/graphql/project/createProject.gql");
      this.saveForm();
    },
    saveForm() {
      this.formLoading = true;
      this.$apollo
        .mutate({
          // Query
          mutation: this.mutation,
          // Parameters
          variables: {
            id: this.formData.id,
            name: this.formData.name,
            description: this.formData.description,
            owner: this.formData.owner.id,
            manager: this.formData.manager.id,
            client: this.formData.client.id
          }
        })
        .then(data => {
          // report success
          this.$emit("project-form-closed", data);
          this.$emit("project-form-saved", data);
        })
        .catch(error => {
          // Error
          console.error(error);
          // update user....
          this.formLoading = false;
        });
    }
  },
  watch: {
    project: {
      handler: function() {
        this.formData = { ...this.defaultForm, ...this.project };
      },
      deep: true
    }
  }
};
</script>
